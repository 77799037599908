@import '@fontsource/poppins/500.css';
@import '@fontsource/poppins/600.css';

html,
body,
#root,
.App {
  height: 100%;
}

body {
  margin: 0;
  font-family:
    'Poppins',
    -apple-system,
    sans-serif;
  font-weight: 500;
  text-rendering: geometricPrecision;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media print {
  .trip-details-wrapper::before {
    content: '';
    font-size: 23px;
    padding: 20px;
  }

  .reservation-details-wrapper::before {
    content: 'Reservation Details';
    font-size: 23px;
    padding: 20px;
  }

  .css-arpunx-MuiModal-root-MuiDrawer-root .MuiDrawer-paper > div:last-child {
    display: none !important;
  }

  .content-block {
    padding: 0 40px !important;
  }

  button,
  a,
  .no-print {
    display: none !important;
  }

  .force-print {
    display: block !important;
  }
}

.react-datepicker--time-only .react-datepicker__time-container {
  min-width: 140px;
  font-family: Poppins;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box {
  width: 100%;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background-color: rgb(255, 0, 24);
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected:hover {
  background-color: #222222;
  color: white;
}

.pac-container {
  z-index: 9999 !important;
}
